<template>
  <div
    :class="{ 'has-error': !isValid && validityChecked }"
    class="datepicker-with-label"
  >
    <label :for="computedName" class="datepicker-label">
      {{ name }}
      <span v-if="!isValid && validityChecked"> is verplicht.</span>
      <span v-if="required" class="field-required">*</span>
    </label>

    <div class="datepicker-container">
      <input
        :id="computedName"
        ref="datepicker"
        class="datepicker"
        type="date"
      />
      <i class="icon--calendar-alt" />
    </div>
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

export default {
  name: "DjlDatepicker",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
      default: "DatePicker",
    },
    required: {
      type: Boolean,
      required: true,
      default: true,
    },
    mode: {
      type: String,
      default: "single",
    },
    minDate: {
      type: [Date, String, Number],
      default: undefined,
    },
    maxDate: {
      type: [Date, String, Number],
      default: undefined,
    },
  },

  data() {
    return {
      flatpickrInstance: null,
      flatpickrFormat: "d-m-Y",
      isValid: true,
      validityChecked: false, //becomes true when trying to submit, false again when value of multiselect changed
    };
  },
  computed: {
    computedName() {
      return this.name.toLowerCase().split(" ").join("-");
    },
  },
  watch: {
    $attrs(value, oldValue) {
      if (value.value) {
        this.flatpickrInstance.setDate(value.value); //value.value is v-model
      }
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.flatpickrInstance.destroy();
  },
  methods: {
    validate() {
      return true; //todo: implement a validate function.
    },
    onOpen() {
      this.validityChecked = false;
    },
    onInput(d) {
      this.$emit("input", d[0]);
    },
    init() {
      const options = {
        locale: Dutch,
        dateFormat: this.flatpickrFormat,
        defaultDate: this.$attrs.value,
        onChange: this.onInput,
        mode: this.mode,
        minDate: this.minDate,
        maxDate: this.maxDate,
      };

      this.flatpickrInstance = flatpickr(this.$refs.datepicker, options);
    },
    refresh() {
      this.$nextTick().then(() => {
        this.init();
      });
    },
  },
};
</script>
